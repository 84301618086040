<template>
    <section class="content">
        <!-- Modal -->
        <div class="modal fade" id="modalDashboard" tabindex="-1" role="dialog" ref="modalDashboard" aria-labelledby="modalDashboardLabel" aria-hidden="true" v-if="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalDashboardLabel">Pengumuman</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- <div data-tf-widget="Lo5qkeaa" data-tf-iframe-props="title=Pengumuman" data-tf-medium="snippet" style="width:100%;height:400px;"></div> -->
                        <div class="eidmubarak1443">
                            <table>
                                <tbody>
                                    <tr>
                                        <th colspan="3"><h4>Hai Kak,</h4></th>
                                    </tr>
                                    <tr>
                                        <td colspan="3">Dalam rangka libur Hari Raya Idul Fitri, Operasional BosCOD akan mengikuti list berikut:</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <th colspan="3"><h5>BosCOD</h5></th>
                                    </tr>
                                    <tr class="border-bottom">
                                        <th class="text-top">Layanan</th>
                                        <td class="text-top">: </td>
                                        <td>Layanan Full, namun terbatas di tanggal 2 dan 3 Mei 2022</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <th class="text-top">Rekons</th>
                                        <td class="text-top">: </td>
                                        <td>Robot autorekons untuk paket diterima tetap jalan seperti biasa</td>
                                    </tr>
                                    <tr>
                                        <th class="text-top">Pencairan&nbsp;Dana</th>
                                        <td class="text-top">: </td>
                                        <td>Layanan tidak tersedia di tanggal 2 dan 3 Mei 2022. Tanggal 1 &amp; 4 Mei, pencairan saldo dilakukan pada kloter 3, yaitu Penarikan saldo sampai jam 15.30, ditransfer jam 16.00-17.00</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <th class="text-top">ID Express</th>
                                        <td class="text-top">: </td>
                                        <td>Layanan normal, namun terbatas di tanggal 2 Mei 2022</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <th class="text-top">JNE</th>
                                        <td class="text-top">: </td>
                                        <td>Layanan normal, namun terbatas di tanggal 1-4 Mei 2022</td>
                                    </tr>
                                    <tr>
                                        <th class="text-top">SiCepat</th>
                                        <td class="text-top">: </td>
                                        <td>Layanan pickup not available di tanggal 1-3 Mei 2022</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                            <center>
                                <img class="w-100" :src="modalImage" alt="Gambar gagal dimuat, Klik untuk mengunduh." />
                            </center>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <label><input type="checkbox" @change="hideModal($event)"> Jangan tampilkan lagi</label>
                        <button type="button" class="btn btn-primary" data-dismiss="modal" v-if="false">Done</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
    name: "ModalDashboard",
    props: {
        show: Boolean,
        value: String,
    },
    data() {
        return {
            modalImage: "https://i.ibb.co/jy02mgW/Whats-App-Image-2022-04-27-at-2-20-33-PM.jpg",
        };
    },
    components: {
    },
    created() {},
    methods: {
        hideModal(val) {
            document.cookie = "eidmubarak1443=" + val.target.checked;
        },
        submitForm: function (ev) {
            var items = [];
            /*if(this.files.length == 0)
      {
        Swal.fire("Pilih lampiran", "Lampiran tidak boleh kosong", "error");
        return false;
      }*/
            for (var i in this.files) {
                items.push(this.files[i].code);
            }
            if (!this.value.length) return;
            var data = {
                orders: this.value,
                type: this.submitType,
                note: this.form.note
            };
            authFetch("/ticket/claim/close", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                .then(res => {
                    if (res.status === 201) {} else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    if (js.success) {
                        this.form = {};
                        this.files = [];
                        this.$emit("change");
                        $(this.$refs.formClosingClaim).modal("hide");
                    } else {
                        this.message = js.desc;
                    }
                });
        },
    },
    mounted() {
        // console.log("warehouse1", this.value);
        const e = this.$refs;
        // if (this.show === true) $(e.formSelectPickup).modal("show");
        // console.log(this.show);
        $(e.formClosingClaim).on("show.bs.modal", e => {
            if (!this.loaded) {
                this.loaded = true;
            }
        });

        $(e.formClosingClaim).on("hide.bs.modal", e => {
            this.message = "";
        });
    },
    watch: {
        show: function (old, val) {
            $(this.$refs.modalDashboard).modal("show");
        },
        value: function (old, val) {}
    }
};
</script>
<style scoped>
.text-top {
    vertical-align: top;
}
</style>